import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 500px;
  display: flex;
  align-items: center;
  padding-top: 40px;

  @media ${device.tablet} {
    padding-top: 60px;
  }
  @media ${device.desktopLG} {
    padding-top: 80px;
  }
  @media ${device.desktopXL} {
    padding-top: 100px;
  }

  h4 {
    margin-bottom: 0;
  }
`
