import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      creditoCorrentistas: imageSharp(fluid: {originalName: { regex: "/lp-credito-correntistas/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoCarousel0: imageSharp(fluid: {originalName: { regex: "/lp-credito-carousel-0/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoCarousel1: imageSharp(fluid: {originalName: { regex: "/lp-credito-carousel-1/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoCarousel2: imageSharp(fluid: {originalName: { regex: "/lp-credito-carousel-2/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoCarousel3: imageSharp(fluid: {originalName: { regex: "/lp-credito-carousel-3/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoQuerSaber: imageSharp(fluid: {originalName: { regex: "/lp-credito-quer-saber/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }    
      lpCreditoAbraSuaConta: imageSharp(fluid: {originalName: { regex: "/lp-credito-abra-sua-conta/" }}) {
        fluid(maxHeight: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      popupImageHomeCredito: imageSharp(fluid: {originalName: { regex: "/popup-image-home-credito/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }    
    }
  `)

  return pageQuery
}

export default PageQuery
