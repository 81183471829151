import React, { useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import data from './data.json'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button } from './style'

type CarouselProps = {
  upTitle: string;
  title: string;
  description: string;
  link: string;
  image: string;
  alt?: string;
}

const DobraCarousel = () => {
  const images = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ carouselIndex, setCarouselIndex ] = useState(0)

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 order-1 order-md-2'>
            <Img fluid={images[data[carouselIndex].image].fluid} alt={data[carouselIndex].alt} />
          </div>
          <div className='col-12 col-md-6 col-xl-5 order-2 order-md-1'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(next: number) => setCarouselIndex(next)}
            >
              {
              data.map((item: CarouselProps, index: number) => (
                <div key={index} className='mx-1'>
                  <h3 className='fs-18 fs-md-12 fs-lg-17 lh-22 lh-md-14 lh-lg-22 fw-700 textUp'>{item.upTitle}</h3>
                  <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 my-xl-4 fw-500 text-grayscale--500'>{item.title}</h2>
                  <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--500'>{item.description}</p>
                  <Button
                    href={item.link}
                    rel='noreferrer'
                    target='_blank'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_03',
                        element_action: 'click button',
                        section_name: item.upTitle,
                        element_name: 'Simule agora',
                        redirect_url: item.link,
                      })
                    }}
                  >
                    Simule agora
                  </Button>
                </div>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DobraCarousel
