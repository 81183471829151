import { breakpoints } from 'src/styles/breakpoints'
import { orange, white, brand } from 'src/styles/colors'
import styled from 'styled-components'

const bgMd = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra5-768/image.webp'
const bgLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra5-1024/image.webp'
const bgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra7-web/image.webp'

export const SectionWrapper = styled.section`
   background-color: ${brand.primary};


   @media (min-width: ${breakpoints.md}) {
    background-image: url(${bgMd});
    background-repeat: no-repeat;
    background-position: left ;
    background-color: ${orange.extra};
    min-height: 285px;
   }


   @media (min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    background-repeat: no-repeat;
    background-position: left ;
    background-color: ${orange.extra};
    min-height: 346px;
   }

   @media (min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    background-repeat: no-repeat;
    background-position: left ;
    background-color: ${orange.extra};
    min-height: 454px;
   }

`
export const Button = styled.button`
  background: ${white};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: ${brand.primary};
  margin-top: 24px;

  &:outline, &:hover {
    outline: none;
    color: ${brand.primary} ;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 32px;
  }

`
