import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

import { Card } from './style'
import cardJSON from './data.json'

// Icons
import IcArmchair from '@interco/icons/core/home-objects/ic_armchair'
import IcSmartphone from '@interco/icons/core/media-communication/ic_smartphone'
import IcCoinArrowDownIn from '@interco/icons/core/finance/ic_coin_arrow_down_in'
type cardProps = {
  icon: string;
  title: string;
  text: string;
}

const ConhecaAsVantagensDeContratar = () => {
  const width = useWidth(300)

  const icons = {
    credit: <IcArmchair height={width <= widths.md ? 32 : 72} width={width <= widths.md ? 32 : 72} color='#943D15' />,
    smartphones: <IcSmartphone height={width <= widths.md ? 32 : 72} width={width <= widths.md ? 32 : 72} color='#943D15' />,
    moneyDown: <IcCoinArrowDownIn height={width <= widths.md ? 32 : 72} width={width <= widths.md ? 32 : 72} color='#943D15' />,

  }

  const handleIcon = (icon: string) => {
    switch (icon) {
      case 'credit':
        return icons.credit
      case 'smartphones':
        return icons.smartphones
      case 'moneyDown':
        return icons.moneyDown
      default:
        return icons.moneyDown
    }
  }

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-28 lh-33 fs-xl-48 lh-xl-52 text-grayscale--500 fw-500 mb-5'>
              Conheça as vantagens de contratar seu crédito no Inter
            </h2>
          </div>
          <div className='col-12'>
            <div className='row'>
              {
                  cardJSON.map((item: cardProps) => (
                    <div className='col-12 col-md-4' key={item.text}>
                      <Card>
                        <div className='row'>
                          <div className='col-2 col-md-12 mb-md-2'>
                            {handleIcon(item.icon)}
                          </div>
                          <div className='col-10 col-md-12'>
                            <h3 className='fs-20 lh-24 fs-xl-24 lh-xl-28 text-grayscale--500 fw-500 mb-2'>{item.title}</h3>
                            <p className='fs-14 lh-16 fs-lg-16 lh-lg-19 text-grayscale--500 fw-400'>
                              {item.text}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))
                }
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default ConhecaAsVantagensDeContratar
