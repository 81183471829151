import React from 'react'
import Faq from 'src/components/Faq/index'
import { structuredData } from '../../pageContext.json'

// Styles
import { SectionWrapper } from './style'

const FAQComponent = () => {
  return (
    <SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='mb-4'>
            <h2 className='fs-24 lh-30 fw-600 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--400'>
              Perguntas frequentes sobre Crédito
            </h2>
          </div>
          <Faq
            answerData={structuredData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
          />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
