import { breakpoints } from 'src/styles/breakpoints'
import { orange, white, brand } from 'src/styles/colors'
import styled from 'styled-components'

const bgLg = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra4-1024/image.webp'
const bgXl = 'https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra4-web/image.webp'

export const Section = styled.section`
  background-color: ${orange.extra};


  @media (min-width: ${breakpoints.md}) {
    background-image: url(${bgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 326px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url(${bgLg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 388px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url(${bgXl});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 670px;
  }

`

export const BoxCard = styled.div`
  background: #ffffff;
  border-radius: 32px;
  padding: 48px 32px;
  height: 354px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    height: 230px;
    padding: 24px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 292px;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 364px;
    padding: 32px;
  }

`
export const Button = styled.a`
  background-color: ${brand.primary};
  color: ${white};
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  margin-top: 24px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${white} ;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 24px;
  }
`
export const ButtonReverse = styled.a`
  background-color: ${white};
  color: ${orange.extra};
  border-radius: 8px;
  padding: 8px 16px;
  border: none;
  margin-top: 24px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${orange.extra};
  }

`
