import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'

// Styles
import { Section, BoxCard, Button, ButtonReverse } from './style'
import ImageWebp from 'src/components/ImageWebp'

const JaPossuiUmaProposta = () => {
  const width = useWidth(300)
  return (
    <Section className='py-5 d-flex align-items-center' role='img' aria-label='mulher ruiva sorrindo e usando celular'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-end'>
          <div className='col-12 col-md-6'>
            {
              width >= widths.md ? (
                <BoxCard>
                  <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-48 lh-xl-52 text-grayscale--500 fw-500 mb-md-2 m-lg-4'>
                    Já possui uma proposta?
                  </h2>
                  <p className='fs-16 lh-19 fs-lg-18 lh-lg-21 text-grayscale--400 fw-400 mb-0'>
                    Acesse a Área do Cliente e veja informações atualizadas sobre a sua proposta de crédito ou sobre o seu contrato.
                  </p>
                  <Button
                    target='_blank'
                    rel='noreferrer'
                    href='https://emprestimo.inter.co/login'
                  >
                    Acesse aqui
                  </Button>
                </BoxCard>
              ) : (
                <>
                  <ImageWebp
                    altDescription='mulher ruiva sorrindo e usando celular'
                    arrayNumbers={[]}
                    arrayNumbersHeight={[]}
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/ci-dobra4-360/image.webp'
                  />
                  <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-33 fs-xl-48 lh-xl-52 text-white fw-500 mt-3 mt-md-0 mb-2'>
                    Já possui uma proposta?
                  </h2>
                  <p className='fs-16 lh-19 fs-lg-18 lh-lg-21 text-white fw-400'>
                    Acesse a Área do Cliente e veja informações atualizadas sobre a sua proposta de crédito ou sobre o seu contrato.
                  </p>
                  <ButtonReverse
                    href='https://emprestimo.inter.co/login'
                    target='_blank'
                    rel='noreferrer'
                  >Acesse aqui
                  </ButtonReverse>
                </>
              )
            }

          </div>
        </div>
      </div>
    </Section>
  )
}

export default JaPossuiUmaProposta
