import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background:  #FDF8EE;

  .react-multi-carousel-dot {
    button{
      background-color: #FFDAB7 !important;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra} !important;
    }
  }

`

export const Card = styled.div`
  background-color: ${white};
  border-radius: 16px;
  padding: 24px;
  height: 151px;
  width: 100%;
  position: relative;
  margin-bottom: 32px;
  min-height: 78px;

  @media (min-width: ${breakpoints.md}) {


    &.heightData {
      height: 171px;
      min-height: 78px;

    }
  }

`
export const Box = styled.span`
  background-color: ${orange.extra};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px 5px 16px;
  width: 195px;
  height: 33px;


`
export const IconContainer = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: ${white};
  display: flex;
  align-items: center;
  justify-content: center;

`
export const CardComponent = styled.div`
  position: absolute;
  top: -15px;
  left:  -2px;
  width: 100%;

`
