import { orange, white, brand } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: #F5F6FA;


.react-multi-carousel-dot {
    button{
      background-color: #FFDAB7 !important;
    }
  }

  .react-multi-carousel-dot--active {
    button{
      background-color: ${orange.extra} !important;
    }
  }

.textUp {
  color: #72370E;
}

`
export const Button = styled.a`
  background: ${brand.primary};
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  color: ${white};
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${brand.primary} ;
  }

`
