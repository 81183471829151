import React, { useState } from 'react'
import pageContext from './pageContext.json'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'

import Hero from './sections/hero/_index'
import VejaNossasOpcoesCredito from './sections/veja-nossas-opcoes-credito/_index'
import CreditoPessoal from './sections/credito-pessoal/_index'
import DobraCarousel from './sections/dobra-carousel/_dobraCarousel'
import JaPossuiUmaProposta from './sections/ja-possui-uma-proposta/_index'
import AbraSuaConta from './sections/abra-sua-conta/_abraSuaConta'
import ConhecaAsVantagensDeContratar from './sections/conheca-as-vantagens-de-contratar/_index'
import FAQComponent from './sections/faq/_faq'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const Credito = () => {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_07',
    section_name: 'Abra sua conta gratuita',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountRightForm
        closeModal={() => setIsModalOpen(false)}
        dataLayer={dataLayer}
      />
    </Modal>
  )
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountRightForm}
        <Hero />
        <VejaNossasOpcoesCredito />
        <CreditoPessoal />
        <JaPossuiUmaProposta />
        <DobraCarousel />
        <ConhecaAsVantagensDeContratar />
        <AbraSuaConta setIsModalOpen={setIsModalOpen} setDataLayer={setDataLayer} />
        <FAQComponent />
      </Layout>
    </Wrapper>
  )
}

export default Credito
